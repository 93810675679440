import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//import { Box,Container,Row,Column,FooterLink,Heading,Address,SocialMediaBlock,ContentDiv } from "./pages/ContentStyles";
import { ContentDiv } from "./pages/ContentStyles";

//import 'bootstrap/dist/css/bootstrap.min.css';

import Menu     from "./pages/Menu";
import Footer   from './pages/Footer';

import Home     from "./pages/Home";
//import Info     from "./pages/Info";

import Signup  from "./pages/Signup";

import OrderDetails from "./pages/OrderDetails";

import CookieConsent   from "./pages/CookieConcent"

import Login                  from "./pages/common/Login";
import MyPage                 from "./pages/common/MyPage";
import NoPage                 from "./pages/common/NoPage";
import Contact                from "./pages/common/Contact";
import Invoices               from "./pages/common/Invoices";
import Terminate              from "./pages/common/Terminate";
import Subscriptions          from "./pages/common/Subscriptions";
import InstanceVerify         from "./pages/common/InstanceVerify";
import ChangePassword         from "./pages/common/ChangePassword";
import RequestNewPassword     from "./pages/common/RequestNewPassword";
import RequestNewPasswordSet  from "./pages/common/RequestNewPasswordSet";

import SellReport             from "./pages/SellReport";

import Orders                 from "./pages/Orders";
import QR                     from "./pages/QR";

import Products               from "./pages/Products";

import Shops                  from "./pages/Shops";
import ShopAdd                from "./pages/ShopAdd";
import ShopDetails            from "./pages/Shop";

import Customers              from "./pages/Customers";
import CustomerDetails        from "./pages/Customer";
import Project                from "./pages/Project";
import Example                from "./pages/Example";
import Register               from "./pages/Register/Register";
import Instances              from "./pages/Instances";

import Upload from './pages/upload';

export default function App() {
  return (
    <>

      <ContentDiv>
        <BrowserRouter>
          <Routes>
            <Route index                                element={<Home />} />
            <Route path="signup"                        element={<Signup />} />
            
            <Route path="instance/Invoices"             element={<Invoices />} />
            <Route path="instance/terminate"            element={<Terminate />} />
            <Route path="instance/verify/:GUID"         element={<InstanceVerify />} />
            <Route path="instance/Subscriptions"        element={<Subscriptions />} />

            <Route path="Password/Request/new"            element={<RequestNewPassword />} />
            <Route path="Password/Request/set/:GUID"      element={<RequestNewPasswordSet />} />
            <Route path="Password/change"                 element={<ChangePassword />} />
            
            <Route path="contact"                         element={<Contact />} />           
            <Route path="login"                           element={<Login />} />              
            <Route path="MyPage"                          element={<MyPage />} />   

            <Route path="Orders"                          element={<Orders />} />               
            <Route path="orders/:OrderGUID"               element={<OrderDetails />} />               

            <Route path="Products"                        element={<Products />} />   

            <Route path="Shops"                           element={<Shops />} />   
            <Route path="Shop/Add"                        element={<ShopAdd />} />  
            <Route path="Shop/:ShopGUID"                  element={<ShopDetails />} />   

            <Route path="Customers"                       element={<Customers />} />   
            <Route path="Customer/:CustomerGUID"          element={<CustomerDetails />} />              

            <Route path="QR"                              element={<QR />} />

            <Route path="SellReport"                      element={<SellReport />} />       

            <Route path="Project"                         element={<Project />} />       
            <Route path="Instances"                       element={<Instances />} />       
            <Route path="Example"                         element={<Example />} />       
            <Route path="Uploadx"                         element={<Upload />} />  
            <Route path="Register"                        element={<Register />} /> 
            
            <Route path="*"                               element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      </ContentDiv>
      <Footer />
      <CookieConsent />
    </>
  );
}

//ReactDOM.render(<App />, document.getElementById("root"));
const root = ReactDOM.createRoot(document.getElementById("main") );
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const top = ReactDOM.createRoot(document.getElementById("top") );
top.render(
  <React.StrictMode>
    <Menu />
  </React.StrictMode>
);