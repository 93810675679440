import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import { FaFilePdf } from 'react-icons/fa';
import useBackend, {CallAPI, Loading, SpinnerX} from '../../services/useBackend';
import React from 'react';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:false,
          invoices:null
        }
    }

    CheckAccessToken;

    componentDidMount() {
        this.getData();
    }

    getData = () =>{
      this.setState({ resultVisible:false, spinnerHidden:false  });
      fetch('/backend/invoices/Instance', {
        method: 'GET',
        headers: {
           'Content-Type': 'application/json',
           'accessToken':localStorage.getItem('access_token'),
        },
     }).then(response => response.json())
     .then(data => {
        if (data.errorsException===false) {
            this.setState({ resultVariant :'success', resultVisible:false, resultText: data.resultText, spinnerHidden:true, invoices:data.invoicesList  });
        } else {
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
        }
     })
     .catch(error => {
        this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
      });
    }

    anchorClickHandler = (event)  =>  {
        const InvoiceGUID = event.currentTarget.getAttribute("InvoiceGUID")
        window.open("/backend/invoices/"+InvoiceGUID, "_"+InvoiceGUID);
    }

    render() {
        if (this.state.invoices !== null) {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida>
                        <Rubrik>Mina Fakturor</Rubrik>

                                <Table  responsive="sm">
                                    <thead>
                                        <tr><td><small>#</small></td><td><small>Förfaller</small></td><td><small>Betald</small></td><td><small>PDF</small></td></tr>
                                    </thead>
                                    <tbody>
                                        {this.state.invoices.map((item) => {
                                            return <tr><td>{item.identifier}</td><td>{item.dayToPay}</td><td>{item.payed}</td><td><span className='sub' InvoiceGUID={item.guid} onClick={this.anchorClickHandler}><FaFilePdf /></span></td></tr> 
                                        })}
                                    </tbody>
                                </Table>
                                <small>Belopp angiva exklusive moms.</small>
 
                    </Sida>
                    </Container>
                </ContentWrapper>
            );
        } else {
            return (
                <ContentWrapper>
                <Container>
                    <Sida>
                        <Rubrik>Mina Fakturor</Rubrik>

                        <Stycke>
                            Inga fakutor hittades!
                        </Stycke>    

                    </Sida>
                </Container>
                </ContentWrapper>     
            );                       
        }
    }
}

export default MyComponent;